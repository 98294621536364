import { createContext, useReducer } from 'react';

import type { ComponentProps } from 'react';
import type { WebSocketHeartbeat } from 'shared/features/websocket/WebSocketHeartbeat';

interface IContextProps {
  state: {
    socket: WebSocketHeartbeat | null;
  };
  dispatch: (action: ReduceAction) => void;
}

const initValue = {
  socket: null,
} as IContextProps['state'];

export const WebSocketContext = createContext({
  state: initValue,
} as IContextProps);

export const WebSocketAction = {
  Init: 'INIT',
} as const;

type ReduceAction = {
  type: typeof WebSocketAction.Init;
  payload: WebSocketHeartbeat;
};

function reducer(state: IContextProps['state'], action: ReduceAction) {
  switch (action.type) {
    case WebSocketAction.Init:
      return { ...state, socket: action.payload };
    default:
      return state;
  }
}

type WebSocketProviderProps = Omit<ComponentProps<typeof WebSocketContext.Provider>, 'value'>;

export const WebSocketProvider = (props: WebSocketProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initValue);

  return <WebSocketContext.Provider {...props} value={{ state, dispatch }} />;
};
