import DrawerWrapper from 'antd/es/drawer';

import type { DrawerProps as AntDrawerProps } from 'antd/es/drawer';
import type { ReactNode } from 'react';

import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

const StyledDrawer = styled(DrawerWrapper)`
  &.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: none;
    opacity: 1;
    transition:
      0.3s transform cubic-bezier(0, 0.95, 0.36, 0.98) 0.2s,
      0.4s opacity ease-in-out 0.2s;
  }
  .ant-drawer-content-wrapper {
    opacity: 0;
    transition:
      0.8s transform cubic-bezier(0, 0.95, 0.36, 0.98),
      0.5s opacity ease-in-out 0.15s;
  }
  .ant-drawer-content {
    overflow: hidden;
  }
  .ant-drawer-footer {
    padding: 12px 32px;
  }
`;

interface DrawerProps extends AntDrawerProps {
  children?: ReactNode;
  isLoading?: boolean;
}

export const Drawer = ({ width = theme.sizes.drawerWidth, isLoading, ...props }: DrawerProps) => {
  return (
    <StyledDrawer styles={{ body: { padding: 0 } }} width={width} {...props} loading={isLoading} />
  );
};
