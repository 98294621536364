import { LOCAL_STORAGE_LAST_CHANNEL, LOCAL_STORAGE_LAST_CHANNEL_TYPE } from 'AppConstants';

import type { FlatChannel } from 'shared/models/account/channel';

interface Channel {
  id: number;
  type: string;
}

const getLastChannel = (): Channel | undefined => {
  const channelIdStr = localStorage.getItem(LOCAL_STORAGE_LAST_CHANNEL);
  const channelType = localStorage.getItem(LOCAL_STORAGE_LAST_CHANNEL_TYPE);
  if (channelIdStr && channelType) {
    const channelId = parseInt(channelIdStr, 10);
    if (channelId) {
      return {
        id: channelId,
        type: channelType,
      };
    }
  }
};

export const findChannel = (channels: Array<FlatChannel>): FlatChannel | null => {
  if (channels.length === 0) {
    return null;
  }
  const cacheChannel = getLastChannel();

  if (cacheChannel) {
    const channel = channels.find((d) => d.id === cacheChannel.id && d.type === cacheChannel.type);
    if (channel) {
      return channel;
    }
  }

  return channels[0];
};

export const isUserDetailAPI = (url: string): boolean => {
  return /^\/accounts\/v1\/user\/\d+\/detail\/$/.test(url);
};
