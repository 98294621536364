import { useHandler } from '@chatbotgang/etude/react/useHandler';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { RubatoErrorEvents } from 'lib/axios/rubato/rubatoErrorEventEmitter';

import { rubatoErrorEventEmitter } from 'lib/axios/rubato/rubatoErrorEventEmitter';
import { Button } from 'shared/components/Button';
import { Modal } from 'shared/components/Modal';
import { useToggle } from 'shared/hooks/common/useToggle';

import { useAuth } from '../context';

export const LogoutModal = memo(function LogoutModal() {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const { active: modalOpen, on: openModal, off: closeModal } = useToggle(false);

  const onCancel = useHandler(() => {
    closeModal();
    logout();
  });

  const eventHandler = useHandler<RubatoErrorEvents['authenticationFailedError']>(() => {
    openModal();
  });

  useEffect(
    function bindRubatoErrorEvent() {
      rubatoErrorEventEmitter.on('authenticationFailedError', eventHandler);
      return function cleanup() {
        rubatoErrorEventEmitter.off('authenticationFailedError', eventHandler);
      };
    },
    [eventHandler],
  );

  return (
    <Modal
      title={t('login.sessionExpired.title')}
      open={modalOpen}
      onCancel={onCancel}
      closable={false}
      maskClosable={false}
      footer={[
        <Button key="logout" type="primary" onClick={onCancel}>
          {t('login.sessionExpired.action')}
        </Button>,
      ]}
      width="357px"
    >
      <div>{t('login.sessionExpired.desc')}</div>
    </Modal>
  );
});
