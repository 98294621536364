import { z } from 'zod';

const OrganizationReleaseTierEnum = {
  '1stTier': '1st_tier',
  '2ndTier': '2nd_tier',
  '3rdTier': '3rd_tier',
} as const;

export const OrganizationSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  url_namespace: z.string().nullable(),
  uuid: z.string(),
  has_sms_vendor: z.boolean(),
  module: z
    .object({
      id: z.union([z.string(), z.number()]),
      organization: z.union([z.string(), z.number()]),
      sms: z.boolean(),
    })
    .optional(),
  enable_two_factor: z.boolean(),
  has_sdk: z.boolean(),
  crm_id: z.string().nullable(),
  release_tier: z.nativeEnum(OrganizationReleaseTierEnum),
});

export type Organization = z.infer<typeof OrganizationSchema>;
