import { z } from 'zod';

import { TimezoneDatabaseNameSchema, UserPlanEnum } from 'shared/models/account/common';
import { AvailableLangsSchema } from 'shared/models/i18n';

const ChannelSchema = z.object({
  id: z.number().int(),
  plan: z.nativeEnum(UserPlanEnum),
  name: z.string(),
  enable: z.boolean(),
  expired: z.boolean(),
  expired_at: z.string(),
  timezone: z.union([TimezoneDatabaseNameSchema, z.string()]),
  language_code: AvailableLangsSchema,
  retarget: z.boolean().optional(),
  segment_limit: z.number().int(),
  line_id: z.string().optional(),
  picture_url: z.string().nullable().optional(),
});

export const ChannelTypeEnum = {
  Line: 'line',
  WhatsApp: 'whatsapp',
  Other: 'other',
} as const;

const FlatChannelSchema = ChannelSchema.extend({
  type: z.nativeEnum(ChannelTypeEnum),
});

export type FlatChannel = z.infer<typeof FlatChannelSchema>;

export const ChannelMapSchema = z.record(z.nativeEnum(ChannelTypeEnum), z.array(ChannelSchema));

export type ChannelMap = z.infer<typeof ChannelMapSchema>;

export const ChannelServerRegionEnum = {
  Taiwan: 'tw',
  Japan: 'jp',
} as const;

export const ChannelInfoSchema = z.object({
  id: z.number().int(),
  uuid: z.string().nullable(),
  organization: z.number().int(),
  name: z.string(),
  plan: z.nativeEnum(UserPlanEnum),
  type: z.nativeEnum(ChannelTypeEnum),
  access_token: z.string(),
  channel_id: z.string(),
  channel_secret: z.string(),
  collect_email: z.boolean(),
  collect_mobile: z.boolean(),
  forward_webhook: z.string().nullable(), // Possibly empty string as well as `null`
  language_code: AvailableLangsSchema,
  line_id: z.string(),
  lotame_pixel: z.boolean(),
  module: z.string().nullable(), // This is a comma-delimited string
  redirect_with_custom_utm_term: z.boolean(),
  redirect_with_tag: z.boolean(),
  enable: z.boolean(),
  retarget: z.boolean(),
  timezone: TimezoneDatabaseNameSchema,
  token_expired_time: z.string(),
  created_at: z.string(),
  expired_at: z.string(),
  updated_at: z.string(),
  server_region: z.nativeEnum(ChannelServerRegionEnum),
});

export type ChannelInfo = z.infer<typeof ChannelInfoSchema>;

export const ChannelFeatureStateSchema = z.object({
  check_result: z
    .object({
      chat_mode: z.boolean(),
      webhook: z.boolean(),
    })
    .nullable(),
  error: z.string().nullable(),
});

export type ChannelFeatureState = z.infer<typeof ChannelFeatureStateSchema>;
