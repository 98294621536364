import {
  StatsigProvider as ReactStatsigProvider,
  useClientAsyncInit,
} from '@statsig/react-bindings';

import { ENV, STATSIG_CLIENT_API_KEY } from 'env';

import type { PropsWithChildren } from 'react';

import { AppLoading } from 'app/components/AppLoading';
import { useAuth } from 'context/authContext';
import { useServerRegion } from 'shared/hooks/auth/useServerRegion';

interface StatsigProviderProps {}

export const StatsigProvider = ({ children }: PropsWithChildren<StatsigProviderProps>) => {
  const {
    state: { org },
  } = useAuth();

  const { serverRegion } = useServerRegion();
  const { client, isLoading } = useClientAsyncInit(
    STATSIG_CLIENT_API_KEY,
    {
      userID: 'maac',
      custom: {
        ...(serverRegion && { region: serverRegion }),
        ...(org?.release_tier && { release_tier: org.release_tier }),
      },
      customIDs: {
        ...(org?.uuid && { org_uuid: org.uuid }),
      },
    },
    {
      environment: { tier: ENV },
    },
  );

  if (isLoading) {
    return <AppLoading />;
  }

  return <ReactStatsigProvider client={client}>{children}</ReactStatsigProvider>;
};
