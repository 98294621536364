import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'context/authContext';
import { useFeatureFlag } from 'features/featureFlag';

export const useDisplayLanguage = () => {
  const {
    state: { user },
  } = useAuth();
  const { i18n } = useTranslation();
  const enabledDisplayLanguage = useFeatureFlag('quick-i18n');

  useEffect(() => {
    if (enabledDisplayLanguage === i18n.language) return;
    if (enabledDisplayLanguage) {
      i18n.changeLanguage(enabledDisplayLanguage);
      return;
    }
    if (user?.language_code) {
      i18n.changeLanguage(user.language_code);
    }
  }, [user?.language_code, i18n, enabledDisplayLanguage]);
};
