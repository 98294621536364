import { z } from 'zod';

export const AvailableLangsEnum = {
  en: 'en',
  'zh-hant': 'zh-hant',
  th: 'th',
  ja: 'ja',
} as const;

export const AvailableLangsSchema = z.nativeEnum(AvailableLangsEnum);

export type AvailableLangs = (typeof AvailableLangsEnum)[keyof typeof AvailableLangsEnum];

export const AvailableLangsDisplayEnum = {
  en: 'English',
  'zh-hant': '繁體中文',
  th: 'ภาษาไทย',
  ja: '日本語',
} as const;
