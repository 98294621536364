import { memo } from 'react';

import { useEnabledSomeFeatureFlags } from 'features/featureFlag';
import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

const Display = styled.div`
  && {
    align-items: flex-end;
    bottom: 0;
    color: #8884;
    display: flex;
    font-size: 4em;
    font-weight: 900;
    height: min-content;
    justify-content: flex-end;
    pointer-events: none;
    position: fixed;
    right: 0;
    width: min-content;
    z-index: ${theme.zIndices.ultimate};
    &::before {
      content: 'DevMode';
    }
  }
`;

export const FeatureEnabledHint = memo(function FeatureEnabledHint() {
  const enabledSomeFeatures = useEnabledSomeFeatureFlags();
  return !enabledSomeFeatures ? null : <Display />;
});
