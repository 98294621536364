import { z } from 'zod';

import { ChannelMapSchema } from 'shared/models/account/channel';
import { OrganizationSchema } from 'shared/models/account/organization';
import { SwitchesSchema } from 'shared/models/account/switches';

const UserJwtSchema = z.object({
  exp: z.number().int(),
  iat: z.number().int(),
  iss: z.string(),
  nbf: z.number().int(),
  org: z.number().int(),
  scope: z.string(),
  sub: z.string(),
  user: z.number().int(),
});

export type UserJwt = z.infer<typeof UserJwtSchema>;

const UserInfoSchema = z.object({
  id: z.number().int(),
  username: z.string(),
  date_joined: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  language_code: z.string(),
  zendesk_token: z.string().nullable(),
});

export type UserInfo = z.infer<typeof UserInfoSchema>;

export const UserDetailSchema = z.object({
  user: UserInfoSchema,
  org: OrganizationSchema,
  channels: ChannelMapSchema,
  switches: SwitchesSchema,
});

export type UserDetail = z.infer<typeof UserDetailSchema>;

const MaacRoleNameSchema = z.custom<`maac:${string}`>().refine((value) => {
  return value.startsWith('maac:');
});

type MaacRoleName = z.infer<typeof MaacRoleNameSchema>;

export const isMaacRole = (role: string): role is MaacRoleName => {
  return MaacRoleNameSchema.safeParse(role).success;
};

export const MaacRoleEnum = {
  Admin: 'maac:admin',
  Marketer: 'maac:marketer',
} as const satisfies Record<string, MaacRoleName>;

const MaacRoleEnumSchema = z.nativeEnum(MaacRoleEnum);

export type MaacRole = z.infer<typeof MaacRoleEnumSchema>;

export const maacRoleMap = {
  [MaacRoleEnum.Admin]: 'Admin',
  [MaacRoleEnum.Marketer]: 'Marketer',
} satisfies Record<MaacRole, keyof typeof MaacRoleEnum>;
