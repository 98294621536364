import { memo } from 'react';

import { Content } from 'app/components/DevMode/Content';
import { FeatureEnabledHint } from 'app/components/DevMode/FeatureEnabledHint';
import { useDevModeStore } from 'app/components/DevMode/stores/useDevModeStore';
import { registerClicks } from 'app/components/DevMode/utils/registerClicks';
import { registerComboKeys } from 'app/components/DevMode/utils/registerComboKeys';
import { registerCommand } from 'app/components/DevMode/utils/registerCommand';
import { Modal } from 'shared/components/Modal';
import { useHandler } from 'shared/hooks/common/useEventCallback';

registerComboKeys(useDevModeStore.getState().open);
registerClicks(useDevModeStore.getState().open);
registerCommand(useDevModeStore);

export const DevMode = memo(function DevMode() {
  const { opened, close } = useDevModeStore(
    useHandler(({ opened, close }) => ({
      opened,
      close,
    })),
  );
  return (
    <>
      <Modal
        open={opened}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={close}
        footer={null}
      >
        <Content />
      </Modal>
      <FeatureEnabledHint />
    </>
  );
});
