import { createVarCall } from '@chatbotgang/motif';
import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import { forwardRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import type { ToastType } from './store';
import type { FC, PropsWithChildren, ReactNode } from 'react';

import { MotifIcon } from 'icons/motif';
import { Button } from 'shared/components/Button';
import { ToastTypeEnum, useToastStore } from 'shared/components/Toast/store';
import { Typography } from 'shared/components/Typography';
import { theme } from 'theme';

const statusIconMap = {
  [ToastTypeEnum.success]: <MotifIcon un-i-motif="circle_check_fill" />,
  [ToastTypeEnum.warning]: <MotifIcon un-i-motif="warning_fill" />,
  [ToastTypeEnum.error]: <MotifIcon un-i-motif="circle_cross_fill" />,
} satisfies Record<ToastType, ReactNode>;

const toastColorMap: Record<
  NonNullable<ToastType>,
  { bgColor: string; iconColor: string; shadowColor: string }
> = {
  success: {
    bgColor: createVarCall('--toast-bg-success'),
    iconColor: createVarCall('--toast-fg-success'),
    shadowColor: createVarCall('--interactive-fg-greenPress'),
  },
  warning: {
    bgColor: createVarCall('--toast-bg-warning'),
    iconColor: createVarCall('--toast-fg-warning'),
    shadowColor: createVarCall('--interactive-fg-yellowPress'),
  },
  error: {
    bgColor: createVarCall('--toast-bg-error'),
    iconColor: createVarCall('--toast-fg-error'),
    shadowColor: createVarCall('--interactive-bg-redPress'),
  },
};

interface ToastProps {
  title?: string;
  content: string;
  type: ToastType;
  onClose: () => void;
}

const ToastContainerWrapper = styled.div`
  position: fixed;
  inset: 24px 0;
  height: fit-content;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  pointer-events: none;
  z-index: ${theme.zIndices.toastDefault};
`;

const StyledToast = styled.div<{ type: ToastProps['type'] }>`
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
  min-width: 320px;
  max-width: 600px;
  padding: 8px 12px;
  border-radius: 4px;
  box-shadow: 0 0 8px 0
    color-mix(in srgb, ${({ type }) => toastColorMap[type].shadowColor}, transparent 80%);
  background-color: ${({ type }) => toastColorMap[type].bgColor};
  color: ${({ type }) => toastColorMap[type].iconColor};
  pointer-events: auto;
`;

const ToastContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ToastMessage = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 228px;
`;

const ToastTypography = styled(Typography.Body)`
  word-break: break-word;
  white-space: normal;
`;

const StatusIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 20px;
`;

const CloseButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  padding: 0;
  color: ${createVarCall('--button-black-fgEnable')};
  background: none;
  border: none;
  cursor: pointer;
  flex-shrink: 0;
`;

export const Toast = forwardRef<HTMLDivElement, ToastProps>(
  ({ title, content, type = ToastTypeEnum.success, onClose }, ref) => {
    const statusIcon = statusIconMap[type];

    return (
      <motion.div
        ref={ref}
        layout={true}
        initial={{ opacity: 0, y: -24 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -24 }}
        transition={{
          type: 'spring',
          ease: 'easeOut',
          duration: 0.5,
        }}
      >
        <StyledToast type={type}>
          <ToastContent>
            <StatusIcon>{statusIcon}</StatusIcon>
            <ToastMessage>
              {title ? <ToastTypography fontWeight="bold">{title}</ToastTypography> : null}
              <ToastTypography fontWeight="regular">{content}</ToastTypography>
            </ToastMessage>
          </ToastContent>
          <CloseButton
            onClick={onClose}
            type="text"
            shape="circle"
            icon={<MotifIcon un-i-motif="cross" />}
          />
        </StyledToast>
      </motion.div>
    );
  },
);

const getPortalContainer = (): HTMLElement => {
  const TOAST_CONTAINER_ID = 'toast-container';
  let container = document.getElementById(TOAST_CONTAINER_ID);

  if (!container) {
    container = document.createElement('div');
    container.id = TOAST_CONTAINER_ID;
    document.body.appendChild(container);
  }

  return container;
};

const ToastContainer: FC = () => {
  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);
  const toasts = useToastStore((state) => state.toasts);
  const removeToast = useToastStore((state) => state.removeToast);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const container = getPortalContainer();
      setPortalContainer(container);

      return () => {
        document.body.removeChild(container);
      };
    }
  }, []);

  if (!portalContainer) return null;

  return createPortal(
    <ToastContainerWrapper>
      <AnimatePresence mode="popLayout">
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            title={toast.message?.title}
            content={toast.message.content}
            type={toast.type}
            onClose={() => removeToast(toast.id)}
          />
        ))}
      </AnimatePresence>
    </ToastContainerWrapper>,
    portalContainer,
  );
};

export const ToastProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      {children}
      <ToastContainer />
    </>
  );
};
