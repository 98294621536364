import {
  background,
  border,
  color,
  compose,
  flex,
  layout,
  position,
  shadow,
  space,
  system,
  typography,
} from 'styled-system';

import type * as CSS from 'csstype';
import type {
  BackgroundProps,
  BorderProps,
  ColorProps,
  FlexProps,
  LayoutProps,
  OverflowProps,
  PositionProps,
  ShadowProps,
  SpaceProps,
  TypographyProps,
} from 'styled-system';

import { styled } from 'shared/utils/styled';
import { shouldNotForwardSystemProps } from 'shared/utils/styled/styledSystem';

export interface BoxProps
  extends FlexProps,
    PositionProps,
    SpaceProps,
    LayoutProps,
    TypographyProps,
    ColorProps,
    ShadowProps,
    BorderProps,
    BackgroundProps,
    OverflowProps {
  color?: string;
  cursor?: CSS.Properties['cursor'];
}

export const superBoxStyle = compose(
  background,
  border,
  space,
  shadow,
  position,
  typography,
  color,
  layout,
  flex,
  system({
    cursor: true,
  }),
);

export const Box = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardSystemProps(),
})<BoxProps>`
  font-size: 14px;
  ${superBoxStyle}
`;
