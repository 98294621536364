import { memo } from 'react';

import type { TabsProps } from 'shared/components/Tabs';

import { Feature } from 'app/components/DevMode/Feature';
import { Permission } from 'app/components/DevMode/Permission';
import { Tabs } from 'shared/components/Tabs';

export const Content = memo(function Content() {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Features',
      children: <Feature />,
    },
    {
      key: '2',
      label: 'Permission',
      children: <Permission />,
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
});
