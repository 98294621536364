// eslint-disable-next-line import/no-extraneous-dependencies -- It's antd's dependency. Don't install another one to keep the version consistent.
import { StyleProvider } from '@ant-design/cssinjs';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { LocationProvider } from '@reach/router';
// eslint-disable-next-line no-restricted-imports -- Primary import for this functionality
import AntApp from 'antd/es/app';
import ConfigProvider from 'antd/es/config-provider';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ComponentPropsWithRef, ReactNode } from 'react';

import { QueryProvider } from 'app/context/QueryProvider';
import { AuthContextProvider } from 'context/authContext/contextProvider';
import { useDayjsLocale } from 'lib/dayjs/hooks/useDayjsLocale';
import { loadAntDesignLocales } from 'modules/G11n/i18n';
import { ToastProvider } from 'shared/components/Toast';
import { WebSocketProvider } from 'shared/features/websocket';
import { useHandler } from 'shared/hooks/common/useEventCallback';
import { colorTokens } from 'shared/lib/motif';
import { ThemeProvider } from 'shared/utils/styled';
import { antdTheme, theme } from 'theme';

const AntStyleProvider = memo(function AntStyleProvider({ children }: { children: ReactNode }) {
  const { i18n } = useTranslation();

  const [injectElement, setInjectElement] = useState<HTMLDivElement | null>(null);

  const ref = useHandler<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Extract<ComponentPropsWithRef<'div'>['ref'], (...args: Array<any>) => any>
  >((el) => {
    setInjectElement(el);
  });

  useEffect(function removeHeadRcStyle() {
    /**
     * If rc style is injected in the head, it might be caused by an extension
     * based on antd v5. We need to remove it to prevent style conflicts.
     */
    function removeHeadRcStyle() {
      const headRcStyle = document.querySelectorAll('head > style[data-rc-order]');
      headRcStyle.forEach((style) => {
        style.remove();
      });
    }

    removeHeadRcStyle();

    const observer = new MutationObserver(removeHeadRcStyle);

    observer.observe(document.head, {
      childList: true,
      subtree: false,
    });

    return function cleanup() {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div data-antd-css={true} className="maac-antd-style" style={{ display: 'none' }} ref={ref} />
      {!injectElement ? null : (
        // StyleProvider must be placed outside of ConfigProvider or we'll get dangling style tags on the top of body element.
        <StyleProvider container={injectElement}>
          <ConfigProvider
            theme={antdTheme}
            locale={loadAntDesignLocales(i18n.language)}
            button={{ autoInsertSpace: false }}
            wave={{ disabled: true }}
          >
            <AntApp component={false}>{children}</AntApp>
          </ConfigProvider>
        </StyleProvider>
      )}
    </>
  );
});

export const AppProviders = memo(function AppProviders({ children }: { children: ReactNode }) {
  /**
   * Initialize date locales for Dayjs
   */
  useDayjsLocale();

  return (
    <LocationProvider>
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <EmotionThemeProvider theme={{ motifColorTokens: colorTokens }}>
            <AntStyleProvider>
              <QueryProvider>
                <AuthContextProvider>
                  <WebSocketProvider>{children}</WebSocketProvider>
                </AuthContextProvider>
              </QueryProvider>
            </AntStyleProvider>
          </EmotionThemeProvider>
        </ToastProvider>
      </ThemeProvider>
    </LocationProvider>
  );
});
