import styled from '@emotion/styled';
import { Fragment, useCallback, useMemo, useState } from 'react';

import type { ComponentProps, FC } from 'react';
import type { Permission as PermissionType } from 'shared/models/account';

import { useAuth } from 'context/authContext';
import { setPermissions } from 'context/authContext/actions';
import { MotifIcon } from 'icons/motif';
import { Button } from 'shared/components/Button';
import { Input } from 'shared/components/Input';
import { HorizontalRadioGroup, Radio } from 'shared/components/Radio';
import { Typography } from 'shared/components/Typography';
import { PermissionEnum } from 'shared/models/account';
import { MaacRoleEnum, maacRoleMap } from 'shared/models/account/user';

const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

const Section = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Roles = keyof typeof MaacRoleEnum;

const RolePermission: Record<Roles, Array<PermissionType>> = {
  Admin: [
    PermissionEnum.MaacUserRolesAssign,
    PermissionEnum.MaacUserRolesUnassign,
    PermissionEnum.MaacContactUnmask,
    PermissionEnum.MaacExportContactUnmask,
  ],
  Marketer: [],
};

const Permission: FC = () => {
  const {
    state: { permissions },
    dispatch,
    reload,
    isLoading,
  } = useAuth();

  const role: Roles | '' = useMemo(() => {
    const roles = Object.keys(MaacRoleEnum) as Array<keyof typeof MaacRoleEnum>;
    for (const key of roles) {
      if (
        permissions.length === RolePermission[key].length &&
        permissions.every((p) => RolePermission[key].includes(p))
      ) {
        return key;
      }
    }
    return '';
  }, [permissions]);

  const [search, setSearch] = useState('');

  const createPermissionChangeHandler = useCallback(
    (permission: PermissionType): ComponentProps<typeof HorizontalRadioGroup>['onChange'] =>
      (event) => {
        const value = event.target.value;

        const newPermissions =
          value === 'on'
            ? Array.from(new Set([...permissions, permission]))
            : permissions.filter((p) => p !== permission);

        dispatch(setPermissions(newPermissions));
      },
    [permissions, dispatch],
  );

  return (
    <Container>
      <Header>
        <Typography.H1 fontWeight="bold">Permission override</Typography.H1>
        <Button type="default" onClick={reload} loading={isLoading}>
          Reset
        </Button>
      </Header>
      <Section>
        <Typography.H2 fontWeight="bold">Role</Typography.H2>
        <HorizontalRadioGroup
          onChange={(event) => {
            const value = event.target.value;
            const newPermissions = RolePermission[value as Roles];
            dispatch(setPermissions(newPermissions));
          }}
          value={role}
        >
          <Radio value={maacRoleMap[MaacRoleEnum.Admin]}>{maacRoleMap[MaacRoleEnum.Admin]}</Radio>
          <Radio value={maacRoleMap[MaacRoleEnum.Marketer]}>
            {maacRoleMap[MaacRoleEnum.Marketer]}
          </Radio>
        </HorizontalRadioGroup>
      </Section>
      <Section>
        <Typography.H2 fontWeight="bold">Permissions</Typography.H2>
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          allowClear={true}
          prefix={<MotifIcon un-i-motif="magnifier" />}
          placeholder="Search permission name"
        />
        {Object.values(PermissionEnum)
          .filter((permission) => permission.toLowerCase().includes(search.toLowerCase()))
          .map((permission) => (
            <Fragment key={permission}>
              <Typography fontWeight="bold">{permission}</Typography>
              <HorizontalRadioGroup
                key={permission}
                onChange={createPermissionChangeHandler(permission)}
                value={permissions.includes(permission) ? 'on' : 'off'}
              >
                <Radio value="on">On</Radio>
                <Radio value="off">Off</Radio>
              </HorizontalRadioGroup>
            </Fragment>
          ))}
      </Section>
    </Container>
  );
};

export { Permission };
