import { z } from 'zod';

import { makeApi } from 'shared/lib/zodios/makeApi';
import { PermissionSchema } from 'shared/models/account';

const { apiHooks, apiClient } = makeApi('interlude', [
  {
    alias: 'permissions',
    method: 'get',
    path: '/interlude/v1/user/permissions/',
    response: z.object({
      permissions: z.array(PermissionSchema),
    }),
  },
]);

export { apiClient as interludeApiClient, apiHooks as interludeApiHooks };
