import { safePromise } from '@chatbotgang/etude/safe/safePromise';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback, useRef } from 'react';
import { useAsync } from 'react-async';

import type { QueryClient } from '@tanstack/react-query';
import type { BootstrapData } from 'context/authContext/types';
import type { AsyncState } from 'react-async';
import type { Permission } from 'shared/models/account';
import type { ChannelInfo } from 'shared/models/account/channel';

import { flatChannelMap, getUserToken } from 'app/utils';
import {
  getCaacActivationStatus,
  getGoogleAnalyticSetting,
  getUserDetail,
  logout,
} from 'context/authContext/services';
import { findChannel } from 'context/authContext/utils';
import { AuthAxios as axios, isAxiosError } from 'lib/axios';
import { interludeApiClient, interludeApiHooks } from 'shared/api/interlude';

const GET_PERMISSIONS_MAX_RETRY = 4;

const getPermissions = (queryClient: QueryClient): Promise<{ permissions: Array<Permission> }> => {
  // gradually migrate to type safe api client
  return queryClient.fetchQuery({
    queryKey: interludeApiHooks.getKeyByPath('get', '/interlude/v1/user/permissions/'),
    queryFn: async () => {
      return interludeApiClient.permissions();
    },
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    retry: (failureCount, error) => {
      if (error instanceof AxiosError) {
        if (!error.response || !error.request) return false;
        return error.response.status >= 400 && error.response.status < 500
          ? false
          : failureCount < GET_PERMISSIONS_MAX_RETRY;
      }
      return failureCount < GET_PERMISSIONS_MAX_RETRY;
    },
  });
};

const getBotInfo = (botId: number): Promise<ChannelInfo> =>
  axios.get<ChannelInfo>(`/line/v1/bot/${botId}/`).then((resp) => resp.data);

const getUser = async () => {
  const token = getUserToken();

  if (!token) {
    return Promise.resolve(null);
  }

  return getUserDetail(token.id).catch((error) => {
    logout();
    if (isAxiosError(error)) {
      // token expired.
      if (error.response && error.response.status === 403) {
        return Promise.resolve(null);
      }
    }
    return Promise.reject(error);
  });
};

const useBootstrapData = () => {
  const queryClient = useQueryClient();
  // Maintain a stable reference to the queryClient. This prevents issues that would arise from adding queryClient as a dependency to useCallback, which would cause unnecessary re-fetching of getBootstrapData.
  const queryClientRef = useRef(queryClient);

  const getBootstrapData = useCallback(async (): Promise<BootstrapData> => {
    const userData = await getUser();

    if (!userData) {
      return {
        user: null,
        org: null,
        channels: [],
        currentChannel: null,
        currentBotInfo: null,
        ga: [],
        switches: {},
        isCaacActivated: false,
        permissions: [],
      };
    }

    const {
      data: { results: gaSettings },
    } = await getGoogleAnalyticSetting();

    const { channels, ...others } = userData.data;
    const flattenedChannels = flatChannelMap(channels);
    const currentChannel = findChannel(flattenedChannels);
    const currentBotInfo = currentChannel ? await getBotInfo(currentChannel.id) : null;
    const isCaacActivated = currentBotInfo?.channel_id
      ? (await getCaacActivationStatus(currentBotInfo.channel_id)).is_caac_activate
      : false;

    const safeGetPermissionsResult = await safePromise(() =>
      getPermissions(queryClientRef.current),
    );
    const permissions = safeGetPermissionsResult.data?.permissions ?? [];

    return {
      ...others,
      channels: flattenedChannels,
      currentChannel,
      currentBotInfo,
      ga: gaSettings,
      isCaacActivated,
      permissions,
    };
  }, []);

  return { getBootstrapData };
};

/**
 * @deprecated - Migrate to the Rubato API client version
 */
export const useUserQuery = (): AsyncState<BootstrapData> => {
  const { getBootstrapData } = useBootstrapData();

  return useAsync({
    promiseFn: getBootstrapData,
  });
};
