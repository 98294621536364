import {
  bottom,
  color as colorSystem,
  display,
  fontFamily,
  fontSize,
  fontWeight,
  height,
  left,
  letterSpacing,
  lineHeight,
  position,
  right,
  space,
  style,
  textAlign as textAlignSystem,
  textStyle,
  top,
  width,
} from 'styled-system';

import type { DOMAttributes } from 'react';
import type {
  BottomProps,
  ColorProps,
  DisplayProps,
  FontFamilyProps,
  FontSizeProps,
  HeightProps,
  LeftProps,
  LetterSpacingProps,
  LineHeightProps,
  PositionProps,
  RightProps,
  SpaceProps,
  TextAlignProps,
  TextStyleProps,
  TopProps,
  WidthProps,
} from 'styled-system';

import { styled } from 'shared/utils/styled';
import { shouldNotForwardSystemProps } from 'shared/utils/styled/styledSystem';

interface TextEditorProps
  extends FontFamilyProps,
    FontSizeProps,
    SpaceProps,
    ColorProps,
    DisplayProps,
    LineHeightProps,
    TextAlignProps,
    TextStyleProps,
    LetterSpacingProps,
    PositionProps,
    TopProps,
    LeftProps,
    RightProps,
    BottomProps,
    WidthProps,
    HeightProps,
    DOMAttributes<HTMLInputElement> {}

const overflow = style({
  /* stylelint-disable-next-line property-no-unknown -- valid styled-system */
  prop: 'overflow',
});

interface TextEditorInputProps extends TextEditorProps {
  error?: boolean;
  noHover?: boolean;
}

export const TextEditorInput = styled.input.withConfig({
  shouldForwardProp: shouldNotForwardSystemProps('error'),
})<TextEditorInputProps>`
  ${top}
  ${left}
  ${right}
  ${bottom}
  ${width}
  ${height}
  ${fontSize};
  ${fontFamily};
  ${fontWeight};
  ${space};
  ${colorSystem};
  ${overflow};
  ${display};
  ${textAlignSystem};
  ${textStyle};
  ${lineHeight};
  ${letterSpacing};
  position: relative;
  ${position}
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  transition: 0.25s border-color ease-in-out;
  ${({ error, theme }) => (error ? `border-color: ${theme.colors.red006}` : ``)};
  ${({ disabled }) => (disabled ? `cursor: not-allowed` : ``)};
  ${({ noHover, error, theme }) =>
    noHover
      ? ''
      : `
    &:hover {
      border-color: ${error ? '' : theme.colors.neutral004};
    }
  `}
  &:focus {
    border-color: ${({ error, theme }) => (error ? '' : theme.colors.neutral004)};
  }
`;

export const TextEditorTextArea = styled.textarea.withConfig({
  shouldForwardProp: shouldNotForwardSystemProps(),
})<TextEditorProps>`
  ${top}
  ${left}
  ${right}
  ${bottom}
  ${width}
  ${height}
  ${fontSize};
  ${fontFamily};
  ${fontWeight};
  ${space};
  ${colorSystem};
  ${overflow};
  ${display};
  ${textAlignSystem};
  ${textStyle};
  ${lineHeight};
  ${letterSpacing};
  position: relative;
  ${position}
  ${({ theme }) => `border: 1px solid ${theme.colors.neutral004}`};
  border-radius: 4px;
  outline: none;
  transition: 0.25s border-color ease-in-out;
`;
