import { z } from 'zod';

export const PermissionEnum = {
  MaacUserRolesAssign: 'maac:user_roles:assign',
  MaacUserRolesUnassign: 'maac:user_roles:unassign',
  MaacExportContactUnmask: 'maac:export_contact:unmask',
  MaacContactUnmask: 'maac:contact:unmask',
} as const;

export const PermissionSchema = z.nativeEnum(PermissionEnum);

export type Permission = z.infer<typeof PermissionSchema>;

const VerifyOtpErrorEnum = {
  AUTH_OTP_MISMATCH: 'AUTH_OTP_MISMATCH',
  AUTH_OTP_MAX_ATTEMPTS_EXCEEDED: 'AUTH_OTP_MAX_ATTEMPTS_EXCEEDED',
  AUTH_OTP_EXPIRED: 'AUTH_OTP_EXPIRED',
} as const;

export const VerifyOtpErrorSchema = z.object({
  error_code: z.nativeEnum(VerifyOtpErrorEnum),
  errors: z.tuple([]),
  message: z.string(),
});

export type VerifyOtpError = z.infer<typeof VerifyOtpErrorSchema>;

export const InviteUserErrorEnum = {
  USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
  INVALID_TOKEN: 'INVALID_TOKEN',
} as const;

export const InviteUserErrorSchema = z.object({
  error_code: z.nativeEnum(InviteUserErrorEnum),
  errors: z.tuple([]),
  message: z.string(),
});

export type InviteUserError = z.infer<typeof InviteUserErrorSchema>;

export const UserStatusEnum = {
  Enabled: 'enabled',
  Disabled: 'disabled',
} as const;

export const UserItemSchema = z.object({
  id: z.number().int(),
  /** In our system, username is the email */
  username: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  /** This field is not guaranteed to have value, use username instead */
  email: z.string(),
  mobile: z.string().nullable(),
  language_code: z.string(),
  date_joined: z.string(),
  status: z.nativeEnum(UserStatusEnum),
});

export const WrongPasswordSchema = z.object({
  current_password: z.array(z.enum(['wrong current_password'])).optional(),
  new_password: z
    .array(
      z.enum([
        'new_password and confirm_new_password do not match',
        'This password is entirely numeric.',
      ]),
    )
    .optional(),
});

export type WrongPassword = z.infer<typeof WrongPasswordSchema>;

export const NonFieldErrorSchema = z.object({
  non_field_errors: z.array(z.enum(['Same current_password and new_password'])),
});

export type NonFieldError = z.infer<typeof NonFieldErrorSchema>;

export const AuthenticatorVerifyErrorSchema = z.object({
  error_code: z.string(),
  errors: z.tuple([]),
  message: z.literal('Invalid email or password'),
});

export const AuthenticatorVerifyOtpCodeErrorSchema = z.object({
  error_code: z.string(),
  message: z.literal('Invalid OTP code'),
});

export const AuthenticatorVerifyOtpCodeExpiredErrorSchema = z.object({
  error_code: z.string(),
  message: z.literal('Authenticator secret expired'),
});
