import { z } from 'zod';

const SwitchNamesSchema = z.enum([
  'enable_breaking_news_template',
  'enable_deep_link_v2',
  'enable_openapi_webhook',
  'openapi_event_api',
  'show_auto_segment_modal',
  'enable_journey_common_descendant_detection',
  'enable_new_pricing_for_widget',
  'enable_autoreply_api_client',
  'enable_journey_api_client',
  'enable_members_api_client',
  'enable_segment_api_client',
  'enable_maac_ai_insight',
  'enable_cdh_tag_trigger_journey',
  'enable_template_library_refactor',
  'enable_cdh_tag_rule',
]);

export type SwitchNames = z.infer<typeof SwitchNamesSchema>;

export const SwitchesSchema = z.record(SwitchNamesSchema, z.boolean());

export type Switches = z.infer<typeof SwitchesSchema>;

export const SwitchDetailSchema = z.record(SwitchNamesSchema, z.boolean());

export type SwitchDetail = z.infer<typeof SwitchDetailSchema>;
