import { typedEntries } from '@chatbotgang/etude/object/typedEntries';
import jwt_decode from 'jwt-decode';

import {
  LOCAL_STORAGE_LAST_CHANNEL,
  LOCAL_STORAGE_LAST_CHANNEL_TYPE,
  LOCAL_STORAGE_TOKEN,
} from 'AppConstants';

import type { ChannelMap, FlatChannel } from 'shared/models/account/channel';
import type { UserJwt } from 'shared/models/account/user';

export const flatChannelMap = (channelMap: ChannelMap): Array<FlatChannel> => {
  return typedEntries(channelMap)
    .map(([channelName, channels]) => {
      return channels!.map((channel) => ({ ...channel, type: channelName }));
    })
    .reduce((pre, cur) => {
      return pre.concat(cur);
    }, []);
};

export const getUserToken = ():
  | {
      token: string;
      id: number;
    }
  | undefined => {
  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);

  if (typeof token === 'string') {
    const payload = jwt_decode<UserJwt>(token);

    if (payload && payload.user) {
      return {
        token,
        id: payload.user,
      };
    }
  }
};

export const cacheChannel = (channel: { id: number; type: string }): void => {
  localStorage.setItem(LOCAL_STORAGE_LAST_CHANNEL, channel.id.toString());
  localStorage.setItem(LOCAL_STORAGE_LAST_CHANNEL_TYPE, channel.type);
};
