import { nanoid } from 'nanoid';
import { z } from 'zod';
import { create } from 'zustand';

import { MILLISECONDS_IN_SECOND } from 'AppConstants';

const DEFAULT_DURATION = 3;
const DEFAULT_MAX_TOASTS = 5;

export const ToastTypeEnum = {
  success: 'success',
  warning: 'warning',
  error: 'error',
} as const;
export const toastTypeSchema = z.nativeEnum(ToastTypeEnum);
export type ToastType = z.infer<typeof toastTypeSchema>;

interface ToastMessage {
  title?: string;
  content: string;
}

interface ToastItem {
  id: string;
  message: ToastMessage;
  type: ToastType;
}

interface ToastStore {
  toasts: Array<ToastItem>;
  timeoutIds: Map<string, NodeJS.Timeout>;
  toastQueue: Array<ToastItem>;
  addToast: (message: ToastMessage, type: ToastType) => void;
  removeToast: (id: string) => void;
}

export const useToastStore = create<ToastStore>((set, get) => ({
  toasts: [],
  timeoutIds: new Map(),
  toastQueue: [],

  addToast: (message, type) => {
    const id = nanoid();
    const { toasts, timeoutIds, toastQueue } = get();

    if (toasts.length < DEFAULT_MAX_TOASTS) {
      timeoutIds.set(
        id,
        setTimeout(() => get().removeToast(id), DEFAULT_DURATION * MILLISECONDS_IN_SECOND),
      );

      set({
        toasts: [...toasts, { id, message, type }],
        timeoutIds,
      });
    } else {
      set({
        toastQueue: [...toastQueue, { id, message, type }],
      });
    }
  },

  removeToast: (id) => {
    const { toasts, timeoutIds, toastQueue } = get();
    clearTimeout(timeoutIds.get(id));
    const newTimeoutIds = new Map(timeoutIds);
    newTimeoutIds.delete(id);

    const newToasts = toasts.filter((toast) => toast.id !== id);

    if (toastQueue.length > 0) {
      const [nextToast, ...remainingQueue] = toastQueue;
      newTimeoutIds.set(
        nextToast.id,
        setTimeout(
          () => get().removeToast(nextToast.id),
          DEFAULT_DURATION * MILLISECONDS_IN_SECOND,
        ),
      );

      set({
        toasts: [...newToasts, nextToast],
        timeoutIds: newTimeoutIds,
        toastQueue: remainingQueue,
      });
    } else {
      set({
        toasts: newToasts,
        timeoutIds: newTimeoutIds,
      });
    }
  },
}));

export const useToast = () => {
  const addToast = useToastStore((state) => state.addToast);

  return {
    toast: {
      success: (message: ToastMessage) => addToast(message, ToastTypeEnum.success),
      warning: (message: ToastMessage) => addToast(message, ToastTypeEnum.warning),
      error: (message: ToastMessage) => addToast(message, ToastTypeEnum.error),
    },
  };
};
