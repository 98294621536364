import { z } from 'zod';

/**
 * @deprecated We introduced organization level plan in Q124, so this bot/channel level plan is deprecated.
 */
export const UserPlanEnum = {
  TRIAL_PLAN: 0,
  GROWING_PLAN: 1,
  DATA_PLAN: 2,
  BRAND_PLAN: 3,
  ORG_LEVELS_PLAN: 4,
} as const;

export type UserPlan = (typeof UserPlanEnum)[keyof typeof UserPlanEnum];

export const TimezoneDatabaseNameSchema = z.enum(['Asia/Taipei', 'Asia/Tokyo', 'Asia/Bangkok']);
