import jwt_decode from 'jwt-decode';

import { LOCAL_STORAGE_JWT_PAYLOAD, LOCAL_STORAGE_TOKEN } from 'AppConstants';

import type { AxiosResponse } from 'axios';
import type { GoogleAnalyticSetting, PageResponse } from 'components/Types';
import type { LoginResponse, LoginSsoVariable } from 'context/authContext/types';
import type { UserDetail } from 'shared/models/account/user';

import { AnonymousAxios, AuthAxios as axios } from 'lib/axios';

export const handleUserResponse = ({ token }: LoginResponse) => {
  // check if token is valid jwt token, if not, throw error
  const result = jwt_decode(token);
  if (typeof result === 'string' || result === null) {
    throw new Error('Unexpected token error.');
  }

  localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
};

export const getUserDetail = (userId: number | string): Promise<AxiosResponse<UserDetail>> =>
  axios.get<UserDetail>(`/accounts/v1/user/${userId}/detail/`);

export const getGoogleAnalyticSetting = (): Promise<
  AxiosResponse<PageResponse<GoogleAnalyticSetting>>
> => axios.get<PageResponse<GoogleAnalyticSetting>>('/ga/v1/settings');

export const loginWithSso = async (variables: LoginSsoVariable): Promise<void> => {
  const resp = await AnonymousAxios.post<LoginResponse>('/accounts/v1/sso/login/', variables);
  handleUserResponse(resp.data);
};

export const logout = (): Promise<void> => {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_JWT_PAYLOAD);
  return Promise.resolve();
};

export const getCaacActivationStatus = (
  channelId: string,
): Promise<{
  channel_id: number;
  is_caac_activate: boolean;
  born_before_caac: boolean;
}> => axios.get(`/caac/v1/channels/${channelId}/`).then((resp) => resp.data);
