import { forwardRef, memo } from 'react';

import type { ComponentPropsWithRef } from 'react';

import { Tooltip } from 'shared/components/Tooltip';
import { Typography } from 'shared/components/Typography/Typography';
import { styled } from 'shared/utils/styled';

interface TableColumnTitleProps extends ComponentPropsWithRef<'div'> {
  tooltipContent?: React.ReactNode;
  children: React.ReactNode;
}

const TableColumnTitleContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;

export const TableColumnTitle = memo(
  forwardRef<HTMLDivElement, TableColumnTitleProps>(function TableColumnTitle(
    { tooltipContent, children, ...restProps },
    ref,
  ) {
    return (
      <TableColumnTitleContainer {...restProps} ref={ref}>
        <Typography.Body fontWeight="regular">{children}</Typography.Body>
        {tooltipContent ? <Tooltip title={tooltipContent} /> : null}
      </TableColumnTitleContainer>
    );
  }),
);
