import { createVarCall } from '@chatbotgang/motif/tokens/colors';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { forwardRef } from 'react';

import type { SerializedStyles } from '@emotion/react';
import type { ComponentPropsWithRef, CSSProperties, ElementType } from 'react';

type Variant = 'h1' | 'h2' | 'h3' | 'body' | 'note' | 'numericCount' | 'numericValue';

interface TypographyProps {
  variant?: Variant;
  color?: CSSProperties['color'];
  fontWeight?: CSSProperties['fontWeight'] | 'medium' | 'regular';
}

const variantMapping: Record<Variant, keyof JSX.IntrinsicElements> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  body: 'p',
  note: 'span',
  numericCount: 'span',
  numericValue: 'span',
};

const variantStyles: Record<Variant, SerializedStyles> = {
  h1: css`
    font-size: 1.5rem;
    line-height: 1.16;
  `,
  h2: css`
    font-size: 1.125rem;
    line-height: 1.33;
  `,
  h3: css`
    font-size: 1rem;
    line-height: 1.5;
  `,
  body: css`
    font-size: 0.875rem;
    line-height: 1.4;
  `,
  note: css`
    font-size: 0.75rem;
    line-height: 1.33;
  `,
  numericCount: css`
    font-size: 1.5rem;
    line-height: 1.16;
  `,
  numericValue: css`
    font-size: 2.25rem;
    line-height: 1.25;
  `,
};

type TypographyComponentProps<C extends ElementType> = TypographyProps &
  Omit<ComponentPropsWithRef<C>, keyof TypographyProps>;

const TypographyRoot = styled.span<TypographyProps>`
  color: ${({ color }) => color || 'inherit'};
  font-weight: ${({ fontWeight }) => getFontWeight(fontWeight)};
  ${({ variant = 'body' }) => variantStyles[variant]}
`;

const TypographyInternal = forwardRef<HTMLElement, TypographyComponentProps<typeof TypographyRoot>>(
  function TypographyInternal(props, ref) {
    const { variant = 'body', ...other } = props;

    const Component = variantMapping[variant] || 'span';

    return <TypographyRoot as={Component} ref={ref} variant={variant} {...other} />;
  },
);

const H1 = (props: Omit<TypographyComponentProps<'h1'>, 'variant'>) => (
  <TypographyInternal variant="h1" color={createVarCall('--static-fg-title')} {...props} />
);

const H2 = (props: Omit<TypographyComponentProps<'h2'>, 'variant'>) => (
  <TypographyInternal variant="h2" color={createVarCall('--static-fg-title')} {...props} />
);

const H3 = (props: Omit<TypographyComponentProps<'h3'>, 'variant'>) => (
  <TypographyInternal variant="h3" color={createVarCall('--static-fg-title')} {...props} />
);

const Body = (props: Omit<TypographyComponentProps<'p'>, 'variant'>) => (
  <TypographyInternal variant="body" color={createVarCall('--static-fg-body')} {...props} />
);

const SubText = (props: Omit<TypographyComponentProps<'p'>, 'variant'>) => (
  <TypographyInternal variant="body" color={createVarCall('--static-fg-note')} {...props} />
);

const Note = (props: Omit<TypographyComponentProps<'span'>, 'variant'>) => (
  <TypographyInternal variant="note" color={createVarCall('--static-fg-note')} {...props} />
);

const Count = (props: Omit<TypographyComponentProps<'span'>, 'variant'>) => (
  <TypographyInternal
    variant="numericCount"
    color={createVarCall('--static-fg-body')}
    fontWeight={700}
    {...props}
  />
);

const Value = (props: Omit<TypographyComponentProps<'span'>, 'variant'>) => (
  <TypographyInternal variant="numericValue" color={createVarCall('--static-fg-body')} {...props} />
);

const Typography = Object.assign(TypographyInternal, {
  H1,
  H2,
  H3,
  Body,
  SubText,
  Note,
  Count,
  Value,
});

export { Typography };

export const getFontWeight = (weight: TypographyProps['fontWeight'] = 'inherit') => {
  switch (weight) {
    case 'medium':
      return 500;
    case 'regular':
      return 400;
    default:
      return weight;
  }
};
