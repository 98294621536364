import { useNavigate } from '@reach/router';
import { Suspense, useEffect } from 'react';

import { LOCAL_STORAGE_TOKEN } from 'AppConstants';

import { AppLoading } from 'app/components/AppLoading';
import { UnavailableFeatureErrorModal } from 'app/components/AppModals/UnavailableFeatureErrorModal';
import { DevMode } from 'app/components/DevMode';
import { StatsigProvider } from 'app/context/StatsigProvider';
import { useDisplayLanguage } from 'app/hooks/useDisplayLanguage';
import { useUser } from 'context/authContext/context';
import { useCustomerSupport } from 'features/customerSupport/useCustomerSupport';
import { lazyWithRetry } from 'lib/router/lazyRoute';
import { useGaEventSettings } from 'shared/lib/ga/hooks/useGaEventSettings';

const { AppAuthenticated: AuthenticatedApp } = lazyWithRetry(
  () => import(/* webpackChunkName: "authApp" */ './AppAuthenticated'),
);
const { AppUnauthenticated: UnauthenticatedApp } = lazyWithRetry(
  () => import(/* webpackChunkName: "unAuthApp" */ './AppUnauthenticated'),
);

const useSyncLogin = () => {
  const navigate = useNavigate();
  useEffect(
    function handleSyncLogin() {
      const {
        location: { pathname },
      } = window;
      const userToken = localStorage.getItem(LOCAL_STORAGE_TOKEN);

      const logoutEventHandler = (event: StorageEvent) => {
        if (event.key === LOCAL_STORAGE_TOKEN && event.newValue !== null && pathname === '/') {
          navigate('/insight');
        }
      };
      window.addEventListener('storage', logoutEventHandler);
      if (pathname === '/' && userToken !== null) {
        navigate('/insight');
      }

      return () => {
        window.removeEventListener('storage', logoutEventHandler);
      };
    },
    [navigate],
  );
};

const useSyncLogout = () => {
  const navigate = useNavigate();

  useEffect(
    function handleSyncLogout() {
      const logoutEventHandler = (event: StorageEvent) => {
        if (event.key === LOCAL_STORAGE_TOKEN && event.newValue === null) {
          navigate('/');
        }
      };
      window.addEventListener('storage', logoutEventHandler);
      return () => {
        window.removeEventListener('storage', logoutEventHandler);
      };
    },
    [navigate],
  );
};

export const App = () => {
  useSyncLogout();
  useSyncLogin();
  useGaEventSettings();

  const user = useUser();

  // Initialize customer support. Should call this hook at top level component for early initialization of the customer support service.
  useCustomerSupport();

  useDisplayLanguage();

  return (
    <div className="App">
      <Suspense
        fallback={
          <div>
            <div>
              <AppLoading />
            </div>
          </div>
        }
      >
        <StatsigProvider>
          {user ? (
            <>
              <AuthenticatedApp />
              <UnavailableFeatureErrorModal />
            </>
          ) : (
            <UnauthenticatedApp />
          )}
        </StatsigProvider>
      </Suspense>
      <DevMode />
    </div>
  );
};
