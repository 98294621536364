import { action } from 'typesafe-actions';

import type { State } from './context';
import type { Permission } from 'shared/models/account';
import type { ChannelInfo, FlatChannel } from 'shared/models/account/channel';

export enum AuthAction {
  SET_STATE = 'SET_STATE',
  SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL',
  SET_CURRENT_BOT_DATA = 'SET_CURRENT_BOT_DATA ',
  SET_PERMISSIONS = 'SET_PERMISSIONS',
}

export const setState = (
  state: Omit<State, 'permissions'>,
): { type: AuthAction.SET_STATE; payload: Omit<State, 'permissions'> } =>
  action(AuthAction.SET_STATE, state);
export const setCurrentChannel = (
  channel: FlatChannel,
): { type: AuthAction.SET_CURRENT_CHANNEL; payload: FlatChannel } =>
  action(AuthAction.SET_CURRENT_CHANNEL, channel);
export const setPermissions = (
  permissions: Array<Permission>,
): { type: AuthAction.SET_PERMISSIONS; payload: Array<Permission> } =>
  action(AuthAction.SET_PERMISSIONS, permissions);
export const setCurrentBotData = (
  data: ChannelInfo,
): { type: AuthAction.SET_CURRENT_BOT_DATA; payload: ChannelInfo } =>
  action(AuthAction.SET_CURRENT_BOT_DATA, data);
