import { useReducer, useState } from 'react';
import ReactGA4 from 'react-ga4';
import { useTranslation } from 'react-i18next';

import { LOCAL_STORAGE_ORG_ID, LOCAL_STORAGE_USER_ID } from 'AppConstants';

import type { LoginSsoVariable } from 'context/authContext/types';
import type { ReactNode } from 'react';

import { AppLoading } from 'app/components/AppLoading';
import { cacheChannel } from 'app/utils';
import * as actions from 'context/authContext/actions';
import { LogoutModal } from 'context/authContext/components';
import { AuthContext, initValue } from 'context/authContext/context';
import { useUserQuery } from 'context/authContext/hooks/useUserQuery';
import { reducer } from 'context/authContext/reducer';
import * as services from 'context/authContext/services';
import * as Logger from 'lib/logger';
import { ErrorFallbackPage } from 'modules/PageErrorBoundary/ErrorFallbackPage';
import { useEnhancedEffect } from 'shared/hooks/common/useEnhancedEffect';

export const AuthContextProvider = (props: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initValue);
  const { data, isRejected, error, isPending, isSettled, reload, isLoading } = useUserQuery();
  const [firstDetailAttemptFinished, setFirstDetailAttemptFinished] = useState(false);

  const { i18n } = useTranslation();

  useEnhancedEffect(() => {
    if (isSettled) {
      if (data) {
        const { currentChannel, user, org } = data;
        if (currentChannel) {
          cacheChannel({ id: currentChannel.id, type: currentChannel.type });
        }
        if (user) {
          localStorage.setItem(LOCAL_STORAGE_USER_ID, user.id.toString());
        }
        if (org) {
          localStorage.setItem(LOCAL_STORAGE_ORG_ID, org.id.toString());
        }

        dispatch(
          actions.setState({
            ...data,
          }),
        );

        if (user) {
          Logger.setUser(user, org ?? undefined);
        }

        setFirstDetailAttemptFinished(true);
      } else {
        dispatch(actions.setState(initValue));
      }
    }
  }, [isSettled, data, i18n]);

  if (!firstDetailAttemptFinished) {
    if (isPending) {
      return <AppLoading />;
    }
    if (isRejected && error) {
      return <ErrorFallbackPage />;
    }
  }

  const loginWithSso = (loginSsoVariable: LoginSsoVariable) =>
    services.loginWithSso(loginSsoVariable).then(reload);
  const logout = () =>
    services.logout().then(() => {
      Logger.unSetUser();
      ReactGA4.set({
        user_properties: {
          channel_id: null,
          organization_id: null,
          user_id: null,
        },
      });
      reload();
    });

  return (
    <AuthContext.Provider value={{ state, dispatch, loginWithSso, reload, logout, isLoading }}>
      {props.children}
      <LogoutModal />
    </AuthContext.Provider>
  );
};
