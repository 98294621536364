import { captureException, captureMessage, getCurrentScope } from '@sentry/browser';
import objectInspect from 'object-inspect';

import type { Organization } from 'shared/models/account/organization';
import type { UserInfo } from 'shared/models/account/user';

/**
 * Log an error.
 * Please use `inspectMessage` instead of `JSON.stringify` for the value to
 * inspect.
 * In most cases you don't have to log the error everywhere because it will be
 * caught by ErrorBoundary.
 *
 * - Please use this for an unexpected error or a service issue that we have to
 *   fix ASAP, not a known error.
 * - Please log an Error object instead of a string.
 * - Please throw an error with a inspected message.
 * - Please use `inspectMessage` instead of `JSON.stringify` for the value to
 *   inspect.
 * @example
 * ```ts
 * try {
 *   // do something
 * } catch (e) {
 *   // unknown type is valid by `logError()`
 *   logError(e);
 * }
 *
 * // throw an error with a inspected message
 * throw new Error(inspectMessage`Something went wrong, data: ${data}`)
 *
 * // log inline
 * logError(new Error(inspectMessage`Something went wrong, data: ${data}`));
 * ```
 * @param error - Preferred error object to use for logging with error stack.
 * @returns Sentry eventId
 */
export function logError<T = Error>(error: T extends Error ? Error : T): string {
  return error instanceof Error
    ? captureException(error)
    : captureMessage(`Caught a non-error thrown: ${objectInspect(error)}`);
}

export const setUser = (user: UserInfo, org?: Organization): void => {
  getCurrentScope().setUser({
    username: org?.name,
    email: user.username,
    id: user.id.toString(),
  });
  if (org) {
    getCurrentScope().setContext('org', org);
  }
};

export const unSetUser = (): void => {
  getCurrentScope().setUser(null);
  getCurrentScope().setContext('org', null);
};
